import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { filterByTagId } from '../../utils/functions';
import { InterestProps } from './models';

import './Interest.scss';

const Interest: FC<{ data: InterestProps }> = ({
  data: {
    interest: { urls, seo, image, seeMore },
    allArticleList: { nodes: categories },
    allArticle: { nodes: articles },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="interest pb-5">
        <div className="interest__image">
          <Image imageData={image} alt={image.altText} className="w-100" />
        </div>

        {categories.map(({ id, title, url, tags }) => (
          <div className="container section-container" key={id}>
            <div className="row pt-5 pb-4">
              <div className="col">
                <h2 className="text-uppercase">{title}</h2>
              </div>
            </div>
            <div className="row">
              {filterByTagId(articles, tags.id)
                .slice(0, 3)
                .map(({ id: aid, url: articleUrl, name, image: articleImage }) => (
                  <div className="col-12 col-md-4 mb-4" key={aid}>
                    <div className="interest__item h-100 p-3">
                      <div>
                        <Link to={articleUrl} aria-label={name}>
                          <Image imageData={articleImage} alt={name} className="w-100" />
                        </Link>
                      </div>
                      <h5 className="mt-3">
                        <a href={articleUrl}>{name}</a>
                      </h5>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row">
              <div className="col text-end">
                <Link to={url} className="btn btn-golden">
                  {seeMore}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    interest(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      seeMore
    }
    allArticleList {
      nodes {
        id
        title
        url
        tags {
          id
          name
        }
      }
    }
    allArticle {
      nodes {
        id
        url
        name
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export default Interest;
